<template>
	<!--Banner section start-->
	<div class="cy_bread_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">404</h1>
				</div>
			</div>
		</div>
	</div>
	
    <!--error section start-->
	<div class="cy_error_wrapper cy_section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 col-md-12 offset-lg-2">
					<div class="cy_error_data">
						<h1>404</h1>
						<h2>{{reslang['id404_1']}}</h2>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal.</p>
						<a href="/" class="cy_button">{{ reslang['id404_2'] }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                language_strings : ['id404_1', 'id404_2'],
                reslang: [],
            }
		},

		created() {

		},

		mounted() {
            this.mute = true

            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response

                this.mute = false
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')

                this.mute = false
            })
		},

		methods: {

		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').style.display = val ? "block" : "none"
            }
        },
    }
</script>