<template>
    <footer>
        <!--Footer section start-->
        <div class="cy_footer_wrapper cy_section_padding padder_bottom75">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget cy_footer_about">
                            <img src="images/logo.png" alt="logo" class="img-fluid"/>
                            <p v-html="resData.Status"></p>
                        </div>  
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget">
                        <h1 class="widget-title">recent blog</h1>

                        <div class="cy_recent_post" v-for="(blog,index) in latest_blogs" :key="index">
                            <div class="cy_post_img">
                                <router-link :to="'/blog/'+blog.id">
                                    <v-lazy-image :src="blog.thumbnail == null ? 'http://via.placeholder.com/70x70' : base+blog.thumbnail"
                                    style="object-fit: cover;height:70px;width:100%"/>
                                </router-link>
                            </div>
                            <div class="cy_post_data">
                                <h3><router-link :to="'/blog/'+blog.id">{{blog.title}}</router-link></h3>
                                <p>{{ blog.date }}</p>
                            </div>  
                        </div>

                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget">
                            <h1 class="widget-title">contact info</h1>
                        </div>
                        <div class="cy_foo_contact">
                            <span><img src="/images/svg/map-mark.svg" alt="map-mark"></span>
                            <div class="cy_post_info">
                            <p>{{address.Address}} No {{ address.AddressNumber }}, {{address.ZipCode}} {{address.City}}</p>
                            </div>
                        </div>
                        <div class="cy_foo_contact">
                            <span><img src="/images/svg/phone.svg" alt="phone"></span>
                            <div class="cy_post_info">
                            <p><a :href="'tel:'+resData.Phone">{{ resData.Phone }}</a></p>
                            </div>
                        </div>
                        <div class="cy_foo_contact">
                            <span><img src="/images/svg/email.svg" alt="email"></span>
                            <div class="cy_post_info">
                            <p><a :href="'mailto:'+resData.Email">{{ resData.Email }}</a></p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="footer-widget">
                            <form @submit.prevent="newsletters()">
                            <h1 class="widget-title"><i class="fa fa-check-circle"></i> Join Our Newsletter</h1>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :placeholder="reslang.idAddEmail" v-model="newsletter.email" required>
                                <div class="input-group-append">
                                    <button class="cy_button" style="padding: 0em 1em;" type="submit"><i class='fa fa-paper-plane'></i></button>
                                </div>
                            </div>
                            </form>
                        </div>
                        <br/>
                        <div class="footer-widget cy_foo_contact">
                            <p>
                                <router-link to="/privacy" class="link text-info">Privacy</router-link>
                                and
                                <router-link to="/terms" class="link text-info">Terms of use</router-link>
                            </p>
                            <p>
                                <router-link to="/bestingelser" class="link text-info">Bestingelser</router-link>
                                and
                                <router-link to="/frakt" class="link text-info">Frakt</router-link>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Bottom footer start-->
        <div class="cy_btm_footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <P>Copyright &copy; 2023, Tourdeice. All Rights Reserved.</P>
                    </div>
                </div>
            </div>
        </div>

        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
  </footer>
</template>

<script>
export default {
    name: 'app-footer',
    data() {
        return {
            language_strings: ['idAddEmail'],
			base: 'https://www.admin.tourdeice.bike',
            reslang: [],
            resData: [],
            address: [],
            newsletter: {
                email: ''
            },
            latest_blogs: [],
        }
    },
    mounted() {
        if (!this.$storage) {
            this.$storage.setStorageSync('langSessionID', "en")
        }
        if (this.$storage.getStorageSync(('langSessionID') == undefined)) {
            this.$$storage.setStorageSync('langSessionID', "en")
        }

        this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
        var datalangs = this.$store.dispatch('languages', this.language_strings)

        datalangs.then(response => {
            this.reslang = response
        })
        .catch((error) => {
            console.log(error, 'Got nothing for this user except bad news.')
        })


        this.dataCompany()
        this.getLatestBlog()
        // this.getDataNode(17, "privacy")
    },
    methods: {
        dataCompany() {
            var datares = this.$store.dispatch('getDataCompany', 1);

            datares.then((response) => {
                this.resData = response.data
                this.address = response.address.Visit
            });
        },

        getLatestBlog() {
			window.scrollTo(0, 0)

			this.axios.get("blog/latest")
            .then((response) => {
                this.latest_blogs = response.data.data
            })
            .catch((error) => {
                console.log(error)
            })
		},

        getDataNode(id, param) {
            let lang = this.$storage.getStorageSync('langSessionID')
            this.$store.commit('setLang', lang)
            var dataNode = this.$store.dispatch("getNodes", id)

            dataNode
            .then(response => {
                this.$data[param] = response.data
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },

        newsletters() {
            this.axios.post('newsletter', this.newsletter)
            .then((response) => {
                this.newsletter.email = ''
                this.$refs.alert.showAlert(
                    'success',
                    response.data.data
                )
            })
        },
    }
}
</script>
