<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{ reslang['idCart'] }}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- checkout section start -->
    <div class="cy_checkout_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 offset-lg-2">
                    <div class="cy_checkout_box">

                        <div class="woocommerce-billing-fields">
                            <form @submit.prevent="submitEvent()" v-show="showForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idFirstname']" class="form-control" v-model="form.firstname" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idLastname']" class="form-control" v-model="form.lastname" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idPhoneNumber']" class="form-control" v-model="form.phone" required>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" :placeholder="reslang['idEmail']" class="form-control" v-model="form.email" required>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <select class="form-control" v-model="form.country" required>
                                            <option value="NO">Norway</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" :placeholder="reslang['idPostNumber']" class="form-control" v-model="form.postal_code" required>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea :placeholder="reslang['idAddress']" class="form-control" v-model="form.address" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" id="pay_btn" class="cy_button next">{{ reslang['idSubmit'] }}</button>
                            </form>

                            <form @submit.prevent="payment()" v-show="!showForm">
                            <div class="row">
                                <div class="col-lg-12">
                                    <p>{{ reslang.idFreeShipmentAvailableAfter }}</p>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <select v-model="selectedShipment" class="form-control" required>
                                            <option v-if="$store.getters.getPKPNumber != null && $store.getters.finalAmount >= $store.getters.getPKPNumber" :value="{name: 'Free Shipment', price: '0', price_amount: 0}">
                                                Name : Free Shipment,
                                                Price : 0
                                            </option>
                                            <option :value="shipment" v-for="(shipment, index) in shipments" :key="index">
                                                Name : {{ shipment.name }},
                                                Estimated Price : {{ shipment.price }},
                                                Expected Delivery Date : {{ shipment.expectedDeliveryDate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                            <button type="submit" id="pay_btn" class="cy_button next">{{ reslang['idProceedPayment'] }}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
		name: 'cart-page',
		data(){
            return {
                language_strings : ['idCart', 'idCountry', 'idPhoneNumber', 'idEmail', 'idFirstname',
                'idLastname', 'idPostNumber','idAddress', 'idProceedPayment', 'idSubmit', 'idSelectShipment',
                'idFreeShipmentAvailableAfter'
                ],
                reslang: [],
                form: {
                    country: 'NO'
                },
                showForm: true,
                formBring: {  
                    language: "en",
                    withPrice: true,
                    withExpectedDelivery: true,
                    withGuiInformation: true,
                    withEstimatedDeliveryTime: true,
                    edi: true,
                    postingAtPostOffice: true,
                    trace: true,
                    consignments: [
                        {
                            products: [
                                {id: 5800,customerNumber: "20009956895"},
                                {id: 5600,customerNumber: "20009956895"},
                                {id: 3584,customerNumber: "20009956895"},
                                {id: 3570,customerNumber: "20009956895"},
                                {id: 5000,customerNumber: "20009956895"},
                                {id: 4850,customerNumber: "20009956895"},
                                {id: 9300,customerNumber: "20009956895"},
                                {id: 9350,customerNumber: "20009956895"},
                                {id: 9650,customerNumber: "20009956895"},
                                {id: 9000,customerNumber: "20009956895"},
                                {id: 9600,customerNumber: "20009956895"},
                                {id: "SINGLE_INDOOR",customerNumber: "20009956895"},
                                {id: "DOUBLE_INDOOR",customerNumber: "20009956895"},
                                {id: "CURBSIDE",customerNumber: "20009956895"},
                                {id: "CURBSIDE_EVENING",customerNumber: "20009956895"},
                                {id: 3332,customerNumber: "20009956895"},
                                {id: "HOME_DELIVERY_RETURN",customerNumber: "20009956895"},
                                {id: "RETURN_INDOOR",customerNumber: "20009956895"},
                                {id: "RETURN_CURBSIDE",customerNumber: "20009956895"},
                            ],
                            fromCountryCode: "NO",
                            fromPostalCode: "0581",
                            toCountryCode: "",
                            toPostalCode: "",
                            addressLine: "",
                            packages: [
                                {
                                    grossWeight: 0,
                                    length: 0,
                                    width: 0,
                                    height: 0
                                }
                            ]
                        }
                    ]
                },
                shipments: [],
                selectedShipment: {}
            }
		},

		created() {

		},

		mounted() {
            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response

                this.mute = false
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')

                this.mute = false
            })

            if(this.$store.getters.getPKPNumber != null && this.$store.getters.finalAmount >= this.$store.getters.getPKPNumber){
                this.selectedShipment = {name: 'Free Shipment', price: '0', price_amount: 0}
            }
		},

		methods: {
            submitEvent(){
                this.showForm = false
                this.formBring.consignments[0].packages[0].grossWeight = this.$store.getters.totalWeight
                this.formBring.consignments[0].packages[0].length = this.$store.getters.totalLength
                this.formBring.consignments[0].packages[0].width = this.$store.getters.totalWidth
                this.formBring.consignments[0].packages[0].height = this.$store.getters.totalHeight
                this.formBring.consignments[0].toCountryCode = this.form.country
                this.formBring.consignments[0].toPostalCode = this.form.postal_code
                this.formBring.consignments[0].addressLine = this.form.address

                this.axios.post("/order/check-shipment",this.formBring).then((response) => {
                    if(response?.data?.success == 1){
                        var datas = JSON.parse(response.data.data);

                        if(datas?.consignments){
                            let products = datas.consignments[0].products
                            
                            let cheapest_price = {}

                            for(let i = 0; i < products.length ; i++){
                                if(products[i].errors?.length > 0){
                                    console.log(i, products[i])
                                }else{
                                    let ship = {
                                        expectedDeliveryDate : products[i].expectedDelivery.expectedDeliveryDate.day+"/"+products[i].expectedDelivery.expectedDeliveryDate.month+"/"+products[i].expectedDelivery.expectedDeliveryDate.year,
                                        // logo : products[i].guiInformation.logoUrl,
                                        name : products[i].guiInformation.displayName,
                                        price : products[i].price.listPrice.currencyCode+products[i].price.listPrice.priceWithAdditionalServices.amountWithVAT,
                                        price_amount : products[i].price.listPrice.priceWithAdditionalServices.amountWithVAT
                                    }
                                    this.shipments.push(ship)

                                    if(Object.keys(cheapest_price).length == 0){
                                        cheapest_price = ship
                                    }else{
                                    if(cheapest_price?.price > ship.price){
                                            cheapest_price = ship
                                        } 
                                    }
                                }
                            }

                            if(Object.keys(this.selectedShipment).length == 0){
                                this.selectedShipment = cheapest_price
                            }
                        }

                    }else{
                        console.log(response)
                        alert("Something Error")
                    }
                })
                .catch((error) => {
                    console.log(error)
                    alert("Something Error")
                })
            },

            payment(){
                this.form.name = this.form.firstname + ' ' + this.form.lastname
                this.form.CommentInternal = "Name Shipment: "+this.selectedShipment.name+", Price: "+this.selectedShipment.price;
                
                let form_data = {
                    products : this.$store.getters.cart,
                    final_amount : Math.round(parseFloat(this.$store.getters.finalAmount)) + Math.round(parseFloat(this.selectedShipment.price_amount)),
                    total_vat : this.$store.getters.totalVAT,
                    details : this.form
                }

                let btn_pay = document.getElementById("pay_btn");
                btn_pay.disabled = true

                this.axios.post("order/create",form_data)
                .then((response) => {
                    console.log(response)
                    
                    if(response.data.code == 200){
                        window.location.replace(response.data.url)
                    }else{
                        alert("Ups, something wrong")
                    }

                    btn_pay.disabled = false
                })
                .catch((error) => {
                    console.log(error)
                    btn_pay.disabled = false
                    this.$router.push({ name: 'NotFound' })
                })
            }
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>