<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Cart</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- checkout section start -->
    <div class="cy_checkout_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12 offset-lg-2">
                    <div class="cy_checkout_box">

                        <div class="woocommerce-checkout-receipt">
                            <h1>{{ reslang['idThankYou1'] }}</h1>
                            <p>{{ reslang['idThankYou4'] }}</p>
                            <!-- <a href="#" class="cy_button">Print Receipt</a>
                            <a href="#" class="cy_button">Email Receipt</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                language_strings : ['idThankYou1', 'idThankYou2', 'idThankYou3', 'idThankYou4',
                    'idThankYou5', 'idThankYou6', 'idThankYou7', 'idThankYou8', 'idThankYou9'
                ],
                reslang: [],
            }
		},

		created() {

		},

		mounted() {
            this.mute = true

            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response

                this.mute = false
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')

                this.mute = false
            })
		},

		methods: {
            
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        },

        watch: {
            mute(val) {
                document.getElementById('mute').style.display = val ? "block" : "none"
            }
        },
    }
</script>