<template>
	<!--Top bar start-->
    <div class="cy_top_wrapper">
        <div class="cy_top_info">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="cy_top_detail">
                            <ul>
                                <li><a :href="'mailto:'+resData.Email">EMAIL : {{ resData.Email }}</a></li>
                                <li><a :href="'tel:'+resData.Phone">PHONE : {{ resData.Phone }}</a></li>
                                <li>
                                    <ul>
                                        <li v-if="resData.Facebook != null">
                                            <a :href="resData.Facebook" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                        </li>
                                        <li v-if="resData.Twitter != null">
                                            <a :href="resData.Twitter" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                        </li>
                                        <li v-if="resData.Instagram != null">
                                            <a :href="resData.Instagram" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                        </li>
                                        <li v-if="resData.Tiktok != null">
                                            <a :href="resData.Tiktok" target="_blank">
                                                <svg fill="#ffffff" viewBox="0 0 512 512" width="13px" height="13px" style="margin-top:2px" id="icons" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/>
                                                </svg>
                                            </a>
                                        </li>
                                        <!-- <li><a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a></li> -->
                                        <li v-if="resData.LinkedIn != null">
                                            <a :href="resData.LinkedIn" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                        </li>
                                        <!-- <li><a href="#"><i class="fa fa-tumblr" aria-hidden="true"></i></a></li> -->
                                    </ul>
                                </li>

                                <li class="cart"><a style="cursor:pointer;"><i class="fa fa-flag" aria-hidden="true">
                                    <template v-if="this.$storage.getStorageSync('langSessionID') == 'no'"> NO</template>
                                    <template v-if="this.$storage.getStorageSync('langSessionID') == 'en'"> EN</template>
                                </i></a>
                                    <ul class="cart_box">

                                        <li v-for="(lang, index) in ['Norway', 'English']" :key="index">
                                            <div class="cart_section">
                                                <div class="cart_detail">
                                                    <a href="#" @click="changeLang(lang)" class="text-white">{{lang}}</a>
                                                </div>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </li>

                                <li class="cart"><a style="cursor:pointer;"><i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                    <span>{{ $store.getters.cart.length }}</span></a>
                                    <ul class="cart_box">

                                        <li v-for="(cart, index) in $store.getters.cart" :key="cart.id">
                                            <div class="cart_section">
                                                <div class="cart_img">
                                                    <a>
                                                        <img v-if="cart.images[0]" :src="cart.images[0]" alt="cart" class="img-fluid">
                                                        <img v-else src="http://via.placeholder.com/68x68" alt="cart" class="img-fluid">
                                                    </a>
                                                </div>
                                                <div class="cart_detail">
                                                    <h4><router-link :to="'/gear/'+cart.id">{{cart.ProductName}} ({{ cart.quantity }})</router-link></h4>
                                                    <h5>{{cart.ProductCurrency}}{{ cart.Custprice }}</h5>
                                                </div>
                                                <a class="cart_delete" @click="removeProduct(index)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                            </div>
                                        </li>
                                        
                                        <li>
                                            <div class="cart_section">
                                                <div class="cart_total">
                                                    <h4>{{ reslang['idTotal'] }}<span>{{ $store.getters.finalAmount }}</span></h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li v-if="$store.getters.cart.length > 0">
                                            <div class="cart_section">
                                                <router-link to="/cart" class="cy_button btn-block text-center">{{ reslang['idViewCart'] }}</router-link>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- sign icons -->
    <div class="cy_sign_wrapper">
        <h2 v-if="!currentUser" class="signin"><i class="fa fa-sign-in" aria-hidden="true"></i><a data-toggle="modal" data-target="#signinModal">Sign In</a></h2>
        <h2 v-else class="signout"><i class="fa fa-sign-out" aria-hidden="true"></i><a href="javascript:;" @click="logout()">Sign Out</a></h2>
    </div>
    <!-- sign in modal -->
    <!-- Modal -->
    <div class="modal fade" id="signinModal" tabindex="-1" role="dialog" aria-labelledby="signinModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="cy_sign_img">
                            <img src="/img/signin.jpg" alt="popup" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="cy_sign_form">
                            <form @submit.prevent="login()">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>Sign In</h1>
                            <label><b>Email</b></label>
                            <input type="email" v-model="form.email" placeholder="Email..." class="form-control" required>
                            
                            <label><b>Password</b></label>
                            <input type="password" v-model="form.password" placeholder="Password..." class="form-control" required>
                            <center v-show="spinner == false"><button type="submit" class="cy_button">sign in</button></center>
                            <center v-show="spinner == true"><div class="spinner"></div></center>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    
    <!--Menus Start-->
    <div class="cy_menu_wrapper">
        <div class="cy_logo_box">
            <a href="/"><img src="/img/logo-white.png" alt="logo" class="img-fluid"/></a>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <button class="cy_menu_btn">
						<i class="fa fa-bars" aria-hidden="true"></i>
					</button>
                    <div class="cy_menu">
                        <nav>
                            <ul>
                                <li><a href="/" :class="$route.matched[0].path == '/'? 'active': ''">home</a></li>
                                <li><router-link to="/gears" :class="$route.matched[0].path == '/gears'? 'active': ''">Shop</router-link></li>
                                <li><router-link to="/register" :class="$route.matched[0].path == '/register'? 'active': ''">Register</router-link></li>
                                <!-- <li><a href="https://www.shop.tourdeice.bike" target="_blank">Shop</a></li> -->
                                <li><router-link to="/ctc-bike" :class="$route.matched[0].path == '/ctc-bike'? 'active': ''">WIKI</router-link></li>
                                <li><router-link to="/blogs" :class="$route.matched[0].path == '/blogs'? 'active': ''">Blog</router-link></li>
                                <li><router-link to="/contact" :class="$route.matched[0].path == '/contact'? 'active': ''">Contact</router-link></li>
                                <li v-if="currentUser"><router-link to="/my-profile" :class="$route.matched[0].path == '/my-profile'? 'active': ''">My Profile</router-link></li>
                                <!-- <li class="dropdown"><a href="javascript:;">event</a>
                                    <ul class="sub-menu">
                                        <li><a href="event.html">event</a></li>
                                        <li><a href="event_single.html">event single</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown"><a href="javascript:;">blog</a>
                                    <ul class="sub-menu">
                                        <li><a href="blog.html">blog</a></li>
                                        <li><a href="blog_single.html">blog single</a></li>
                                    </ul>
                                </li>
                                <li class="dropdown"><a href="javascript:;">pages</a>
                                    <ul class="sub-menu">
                                        <li><a href="shop.html">shop</a></li>
                                        <li><a href="shop_single.html">shop single</a></li>
                                        <li><a href="cart.html">cart</a></li>
                                        <li><a href="checkout.html">checkout</a></li>
                                        <li><a href="404.html">404</a></li>
                                    </ul>
                                </li>
                                <li><a href="gallery.html">gallery</a></li>
                                <li><a href="contact.html">contact us</a></li> -->
                            </ul>
                        </nav>
                    </div>
                    <!-- <div class="cy_search">
                        <a href="#" class="search_open"><i class="fa fa-search"></i></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- search section -->
    <div class="cy_search_form">
        <button class="search_close"><i class="fa fa-times"></i></button>
        <div class="cy_search_input">
        <input type="search" placeholder="search">
        <i class="fa fa-search"></i>
        </div>
    </div>
    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>

<script>
    import {login} from '../../helpers/auth'
    export default {
		name: 'app-header',
		data(){
            return {
                language_strings : ['idViewCart', 'idTotal'],
                reslang: [],
                resData: [],
                address: [],
                form:{},
                spinner:false,
            }
		},

		created() {

		},

		mounted() {
            if (!this.$storage) {
                this.$storage.setStorageSync('langSessionID', "no")
            }
            if(this.$storage.getStorageSync('langSessionID') == undefined){
                this.$storage.setStorageSync('langSessionID', "no")
            }

            this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
            var datalangs = this.$store.dispatch('languages', this.language_strings)

            datalangs.then(response => {
                this.reslang = response
            })
            .catch((error) => {
                console.log(error, 'Got nothing for this user except bad news.')
            })
            
            this.dataCompany()
		},

		methods: {
            changeLang(lang){
                let lang_sess = 'no'
                if(lang == 'English'){
                    lang_sess = 'en'
                }

                if(lang == 'Norway'){
                    lang_sess = 'no'
                }

                this.$storage.setStorageSync('langSessionID', lang_sess)

                // alert(lang_sess)
                // return
                window.location.replace("/");
            },

            removeProduct(index){
                this.$store.dispatch('removeProduct', index)
            },

            dataCompany() {
                var datares = this.$store.dispatch('getDataCompany', 1);

                datares.then((response) => {
                    this.resData = response.data
                    
                    this.$store.dispatch('savePKPNumber', response.data.PKPNumber)

                    this.address = response.address.Visit
                });
            },

            login(){
                this.spinner = true
                login(this.$data.form)
                .then((res) => {
                    document.getElementById('signinModal').click()
                    this.spinner = false
                    this.$store.commit("loginSuccess", res);
                    this.$router.push({path: "/my-profile"});
                })
                .catch((error) => {
                    console.log(error)
                    this.$refs.alert.showAlert(
                        'danger',
                        error,
                        'Danger'
                    )
                    this.spinner = false        
                })
            },

            logout() {
				this.$store.commit('logout');
                window.location.replace("/")
			},
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>
